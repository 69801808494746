// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---gatsby-theme-purist-src-pages-404-tsx": () => import("./../../../../gatsby-theme-purist/src/pages/404.tsx" /* webpackChunkName: "component---gatsby-theme-purist-src-pages-404-tsx" */),
  "component---gatsby-theme-purist-src-templates-about-me-tsx": () => import("./../../../../gatsby-theme-purist/src/templates/aboutMe.tsx" /* webpackChunkName: "component---gatsby-theme-purist-src-templates-about-me-tsx" */),
  "component---gatsby-theme-purist-src-templates-article-tsx": () => import("./../../../../gatsby-theme-purist/src/templates/article.tsx" /* webpackChunkName: "component---gatsby-theme-purist-src-templates-article-tsx" */),
  "component---gatsby-theme-purist-src-templates-articles-tsx": () => import("./../../../../gatsby-theme-purist/src/templates/articles.tsx" /* webpackChunkName: "component---gatsby-theme-purist-src-templates-articles-tsx" */),
  "component---gatsby-theme-purist-src-templates-home-tsx": () => import("./../../../../gatsby-theme-purist/src/templates/home.tsx" /* webpackChunkName: "component---gatsby-theme-purist-src-templates-home-tsx" */)
}

